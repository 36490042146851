@import "src/assets/colors";

.enableHookesPage {
  background-color: white;
  padding: 0 15px;
  height: 100%;
  font-family: 'Inter', sans-serif;

  &__headerContainer {
    margin-bottom: 14px;
  }
  &__number{
  margin-top: 135px;
    align-self: center;
  }
}

.textContainer {
  margin-bottom: 32px;

  &__title {
    color: $textBlack;
    font-size: 24px;
    font-weight: 800;
    line-height: 40px;
    display: block;
  }

  &__text {
    display: block;
    color: $textBlack;
    font-size: 15px;
    font-weight: 400;
    line-height: 23px;
  }
}

.privacyLink {
  color: $textBlack;
  text-decoration: underline;
}

.itemsContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin-bottom: 32px;

  .hookesItem__icon {
    width: 64px;
    height: 64px;
  }
}

.finalDescription {
  margin-top: 8px;
  display: block;
  color: #1A1B27;
  font-family: Inter;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
}

.flexWrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.finalContentWrapper {
  margin-top: -16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

body {
  min-height: 100vh;
}

#root {
  min-height: 100vh;
}

.emoji {
  font-size: 150px;
  line-height: 1;
}
.hookesItem__image{
  width: 82px;
  height: 82px;
}

.hookesItem__image_switch{
  width: 56px;
  height: auto;
}

*{
  box-sizing: border-box;
  font-family: 'Inter', sans-serif;
}

.imageAA{
  display: inline-block;
  position: relative;
  top: 10px;
  width: 130px;
  margin: -12px 10px 0;
  height: 32px;
}
.imageAAEvery{
  display: inline-block;
  position: relative;
  top: 10px;
  width: 227px;
  margin: -12px 10px 0;
  height: 32px;
}
.imageArrow{
  display: flex;
}
