@import "src/assets/colors";

.hookesItem {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px 10px;
  background-color: $itemsBackground;
  border-radius: 12px;

  &__text {
    color: $textBlack;
    display: inline;
    font-size: 17px;
    font-weight: 400;
    line-height: 26px;
  }

  &__image {
    display: inline;
    position: relative;
    top: 5px;
    height: 24px;
    width: 26px;
    margin: -10px 10px 0;
  }
  &__switchImage {
    display: inline;
    position: relative;
    top: 10px;
    height: 28px;
    width: 36px;
    margin: -10px 10px 0;
  }
}
